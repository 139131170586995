import {HeaderContainer} from "../../components/divs";
import {HeadingText, NameText} from "../../components/texts";

const Header = ({demographics}) => {
    return (
        <HeaderContainer>
            <NameText>
                {demographics?.first_name} {demographics?.last_name}
            </NameText>
        </HeaderContainer>
    )
}

export default Header;
