import {createStitches} from "@stitches/react";

export const {styled, createTheme} = createStitches({
    theme:{
        colors:{
            primary: '#087E52',
            secondary: '#9ACE00',
            light: '#FFFFFF',
            lightgray: '#EFF5FB',
            darkgray: '#F5F5F5',
            grayChat: '#999999',
            darkgrayHeading: '#696969',
            darkgraySubHeading: '#323232',
            information: '#005CE9'
        },
        fonts:{
            primary: 'App',
            secondary: 'Quicksand'
        },
        fontSizes:{
            main: '1.25rem',
            lg: '1.125rem',
            md: '1rem',
            sm: '0.875rem',
            xsm: '0.75rem',
        }
    },
utils:{
      marginX: (value)=>({
          marginLeft: value,
          marginRight: value,

      }),
      marginY: (value)=>({
          marginBottom: value,
          marginTop: value,
      }),
      paddingX: (value)=>({
          paddingLeft: value,
          paddingRight: value,


      }),
      paddingY: (value)=>({
          paddingBottom: value,
          paddingTop: value,
      })
}
})
