import axios from "axios";

const API_URL = process.env.REACT_APP_API_ENDPOINT;

export const fetchDemographics = (id) => {
    return axios(
        {
            method: "GET",
            url: `${API_URL}/demographics`,
            params: {
                user_id: id
            }
        }
    ).then(res => {
        return res.data;
    }).catch(err => {
        return  '{}';
    })
}


export const fetchCurrentInteraction = (id) => {
    return axios(
        {
            method: "GET",
            url: `${API_URL}/current_conversation`,
            params: {
                user_id: id
            }
        }
    ).then(res => {
        return res.data;
    }).catch(err => {
        return '{}';
    })
}


export const fetchHistory = (id) => {
    return axios(
        {
            method: "GET",
            url: `${API_URL}/user_history`,
            params: {
                user_id: id
            }
        }
    ).then(res => {
        const parsed_response = JSON.parse(res.data)
        if(parsed_response && parsed_response.length>0){
           return res.data;
        }
        else{
            return '[]'
        }


    }).catch(err => {
        return '[]';
    })
}

export const fetchUsers = () => {
    return axios(
        {
            method: "GET",
            url: `${API_URL}/all_users`,
        }
    ).then(res => {
        return res.data;
    }).catch(err => {
        return [];
    })
}





