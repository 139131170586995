import {MessageArea, MessageContainer} from "../../components/divs";
import {MessageBodyText, MessageRoleText} from "../../components/texts";
export const UserMessage = ({message}) => {
    return (
        <MessageArea type={'user'}>
            <MessageContainer type={'user'}>
                <MessageRoleText>User</MessageRoleText>
                <MessageBodyText type={'user'}>{message.body}</MessageBodyText>
            </MessageContainer>
        </MessageArea>
    )
}