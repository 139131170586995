import {ConversationContainer} from "../../components/divs";
import {ConversationHeadingText} from "../../components/texts";
import {UserMessage} from "./UserMessage";
import {BotMessage} from "./BotMessage";
import {useParams} from "react-router-dom";

const ConversationPanel = ({conversation}) => {
    const {id} = useParams()
    return(
        <ConversationContainer>
            <ConversationHeadingText>CURRENT CONVERSATION</ConversationHeadingText>
            {(conversation?.messages??[]).map(
                (message, index, messages) => {
                    if(message?.role === "user"){
                        return (
                            <UserMessage key={index} message={message}/>
                        )
                    }
                    if(message?.role === "assistant"){
                        return (
                            <BotMessage user_id={id} key={index} message={message} isLast={index === messages.length -1}/>
                        )
                    }
                    return null;
                }
            )}
        </ConversationContainer>
    )

}

export default ConversationPanel;