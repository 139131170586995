import {DemographicsContainer, InformationContainer} from "../../components/divs";
import {DemographicsHeadingText, DemographicsKey, DemographicsValue} from "../../components/texts";
import {reFormat} from "../../utils";

const DemographicsPanel = ({demographics}) => {
    return (
        <DemographicsContainer>
            <DemographicsHeadingText>
                PATIENT INFORMATION
            </DemographicsHeadingText>
            <InformationContainer>
                <DemographicsKey>Contact number</DemographicsKey>
                <DemographicsValue>{demographics?.cellphone_number}</DemographicsValue>
            </InformationContainer>
            <InformationContainer>
                <DemographicsKey>Gender</DemographicsKey>
                <DemographicsValue>{demographics?.gender? reFormat(demographics?.gender):null}</DemographicsValue>
            </InformationContainer>
            <InformationContainer>
                <DemographicsKey>Date of Birth</DemographicsKey>
                <DemographicsValue>{demographics?.date_of_birth}</DemographicsValue>
            </InformationContainer>
            <InformationContainer>
                <DemographicsKey>Preferred Language</DemographicsKey>
                <DemographicsValue>{demographics?.preferred_langauge? reFormat(demographics?.preferred_langauge):null}</DemographicsValue>
            </InformationContainer>
        </DemographicsContainer>
    )
}

export default DemographicsPanel;