import axios from "axios";

const API_URL = process.env.REACT_APP_API_ENDPOINT;

export const approveMessage = (user_id, message) => {
    return axios(
        {
            method: "POST",
            url: `${API_URL}/verify_message`,
            params: {
                user_id: user_id,
                message: message
            }
        }
    ).then(res => {
        return res.data;
    }).catch(err => {
        return  err;
    })
}