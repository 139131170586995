import {useParams} from "react-router-dom";
import {fetchCurrentInteraction, fetchDemographics, fetchHistory, fetchUsers} from "../apis/user";
import Header from "../widgets/chatApproval/Header";
import DemographicsPanel from "../widgets/chatApproval/DemographicsPanel";
import {ContentContainer, PageContainer} from "../components/divs";
import ConversationPanel from "../widgets/chatApproval/ConversationPanel";
import {useQuery} from "react-query";
import HistoryPanel from "../widgets/chatApproval/HistoryPanel";
import NavPanel from "../widgets/chatNavigator/NavPanel";

const ChatApprovalPage = ({...rest}) =>{
    const {id} = useParams()
    const getInformation = async () =>{
        const information =  await Promise.all([fetchDemographics(id), fetchCurrentInteraction(id), fetchHistory(id), fetchUsers()])
        return {
            demographics: JSON.parse(information[0]),
            current_interaction: JSON.parse(information[1]),
            history: JSON.parse(information[2]),
            users: JSON.parse(information[3])
        }
    }

    const {isLoading, data} = useQuery(
        `${id}_information`,
        getInformation,
        {
            refetchInterval: 5000
        }
    )

    if(isLoading){
        return <></>
    }

    return(
        <PageContainer>
            <NavPanel users={data?.users}/>
            <div>
                <Header demographics={data?.demographics}/>
                <ContentContainer>
                    <DemographicsPanel demographics={data?.demographics}/>
                    <ConversationPanel conversation={data?.current_interaction}/>
                    <HistoryPanel history={data?.history}/>
                </ContentContainer>
            </div>


        </PageContainer>
    )
}

export default ChatApprovalPage