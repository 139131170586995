import {styled} from "../stitches.config";

export const NameText = styled(
    'div',
    {
        fontSize: '2rem',
        fontWeight: '500',
        margin: '0px',
        color: '$darkgraySubHeading'
    }
)

export const HeadingText = styled(
    'div',
    {
        fontSize: '1.5rem',
        fontWeight: '200',
        color: '$darkgrayHeading'
    }
)

export const DemographicsHeadingText = styled(
    'div',{
        fontSize: '1rem',
        fontWeight: '400',
        color: '$darkgraySubHeading',
        marginBottom: '1rem',
    }
)
export const ConversationHeadingText = styled(
    'div',{
        fontSize: '1rem',
        fontWeight: '400',
        color: '$darkgraySubHeading',
        marginBottom: '1rem',
    }
)
export const DemographicsKey = styled(
    'div',{
        fontSize: '0.75rem',
        fontWeight: '400',
        color: '$darkgrayHeading'
    }
)
export const DemographicsValue = styled(
    'div',{
        fontSize: '1rem',
        fontWeight: '400',
        color: '$darkgraySubHeading'
    }
)

export const MessageRoleText = styled(
    'div',{
        fontSize: '1rem',
        fontWeight: '700',
        color: '$darkgraySubHeading'
    }
)

export const MessageBodyText = styled(
    'div',{
        fontSize: 'rem',
        fontWeight: '400',
        variants:{
            type: {
                user: {
                    color: '$lightgray',
                    justifyContent: 'flex-start'
                },
                assistant: {
                    color: '$darkgrayHeading',
                }
            }
        }
    }
)

export const HistoryHeadingText = styled(
    'div',{
        fontSize: '1rem',
        fontWeight: '400',
        color: '$darkgraySubHeading',
        marginBottom: '1rem',
    }
)

export const HistoryDateText = styled(
    'div', {
        fontSize: '1rem',
        fontWeight: '700',
        color: '$darkgraySubHeading'
    }
)

export const HistoryComplianceText = styled(
    'div', {
        fontSize: '1rem',
        fontWeight: '300',
        color: '$darkgrayHeading'
    }
)

export const HistorySymptomsText = styled(
    'div', {
        fontSize: '1rem',
        fontWeight: '300',
        color: '$darkgrayHeading'
    }
)