import {NavContainer} from "../../components/divs";
import {NavItem} from "../../components/buttons";
import {useNavigate, useParams} from "react-router-dom";

const NavPanel = ({users}) => {
        const navigate = useNavigate()
        const {id} = useParams()
        return(
            <NavContainer>
                {
                    users.map((user, idx)=>{
                        return(
                            <NavItem key={idx}
					onClick={()=>{
						navigate(`/verify/${user.user_id}`);
						window.location.reload();
						}}
					selected={user.user_id === id}>
                                {user.name}
                            </NavItem>
                        )
                    })
                }
            </NavContainer>

        )

}

export default NavPanel;
