import {ActivityDiv, HistoryContainer} from "../../components/divs";
import {HistoryComplianceText, HistoryDateText, HistoryHeadingText, HistorySymptomsText} from "../../components/texts";
import Timeline from '@mui/lab/Timeline';
import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    timelineItemClasses,
    TimelineSeparator
} from "@mui/lab";
const HistoryPanel = ({history}) => {
    return(
        <HistoryContainer>

            <HistoryHeadingText>
                ACTIVITY
            </HistoryHeadingText>
            <Timeline position="left"
                      sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                          flex: 0,
                          padding: '25px',
                        },
                      }}
            >
                {
                    history.map((historyItem, index, historyItems)=>{
                        return(
                            <TimelineItem key={index}>
                            <TimelineSeparator sx={{margin:'0px', height: '125px'}}>
                              <TimelineDot color={'primary'} sx={{margin:'0px'}}/>
                                {index === historyItems.length -1 ?
                                    null
                                    :
                                    <TimelineConnector sx={{margin:'0px', backgroundColor: '#999999'}}/>
                                }
                            </TimelineSeparator  >
                            <TimelineContent>
                                <ActivityDiv>
                                    <HistoryDateText>
                                        {new Date(historyItem.date).toDateString()}
                                    </HistoryDateText>
                                    <HistoryComplianceText>
                                        {historyItem.medication_compliance? "Compliant" : "Not Compliant"}
                                    </HistoryComplianceText>
                                    <HistorySymptomsText>
                                        {
                                            historyItem.side_effects? `Side effects: ${historyItem.side_effects}`: null
                                        }
                                    </HistorySymptomsText>
                                </ActivityDiv>
                            </TimelineContent>
                          </TimelineItem>
                        )
                    })
                }

            </Timeline>
        </HistoryContainer>
    )
}
export default HistoryPanel;