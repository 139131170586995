import {styled} from "../stitches.config";

export const NavItem = styled(
    'button',
    {
        height: '50px',
        width: '90%',
        fontSize: '1rem',
        fontWeight: '400',
        color: '$darkgraySubHeading',
        border: '2px solid $darkgray',
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '10px',
        '&:hover':{
            backgroundColor: '$darkgraySubHeading',
            color: 'white',
            cursor: 'pointer'
        },
        variants:{
            selected:{
                true:{
                    backgroundColor: '$darkgraySubHeading',
                    color: 'white',
                }
            }
        }

    }
)