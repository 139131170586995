import {useState} from "react";
import {MessageArea, MessageContainer} from "../../components/divs";
import {MessageBodyText, MessageRoleText} from "../../components/texts";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {approveMessage} from "../../apis/approve_message";
import CheckIcon from '@mui/icons-material/Check';
export const BotMessage = ({user_id, message, isLast}) => {
    const [messageObj, setMessageObj] = useState(message)
    const [editedMessageBody, setEditedMessageBody] = useState(messageObj.body)
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setEditedMessageBody(messageObj.body)
        setOpen(true);
    };

    const approveAndSendMessage = () => {
        approveMessage(user_id, editedMessageBody).then(
            (res)=>{
                const tempMessage = {...messageObj};
                tempMessage.sent = true;
                setMessageObj(tempMessage)
            }
        )


    };


    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirmEdit = () => {
        const tempMessage = {...messageObj};
        tempMessage.body = editedMessageBody;
        setMessageObj(tempMessage)
        setOpen(false);
    }
    return (
        <MessageArea type={'assistant'}>
            <MessageContainer type={'assistant'}>
                <MessageRoleText>Assistant</MessageRoleText>
                <MessageBodyText type={'assistant'}>{messageObj.body}</MessageBodyText>
                {
                    isLast && !messageObj?.sent?
                        <div style={{display:'flex', justifyContent:'flex-end', gap: '10px'}}>
                            <Button style={{width: '100px'}} onClick={handleClickOpen} variant="contained">Update</Button>
                            <Button style={{width: '100px'}} onClick={approveAndSendMessage} variant="contained">Send</Button>
                        </div>
                    :
                        null

                }
                {
                    messageObj?.sent?
                        <div style={{display:'flex', justifyContent:'flex-end'}}>
                            <CheckIcon style={{color: '#005CE9'}}/>
                        </div>
                    :
                        null
                }
            </MessageContainer>
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>Edit response</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    id="editted_bot_response"
                    label="Message"
                    type="text"
                    fullWidth
                    multiline
                    variant="standard"
                    value={editedMessageBody}
                    onChange={
                        (e)=>{
                            setEditedMessageBody(e.target.value);
                        }
                    }

                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} variant={'contained'}>Cancel</Button>
                  <Button onClick={handleConfirmEdit} variant={'contained'}>Confirm</Button>
                </DialogActions>
          </Dialog>
        </MessageArea>
    )
}
