import ChatApprovalPage from "./pages/ChatApprovalPage";
import {Route, Routes} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";

const queryClient = new QueryClient();
function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <div style={{height:'100%'}}>
                <Routes>
                <Route path={'/verify/:id'} element={
                    <ChatApprovalPage/>
                }/>
                </Routes>
            </div>
        </QueryClientProvider>

    );
}

export default App;
