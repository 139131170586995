import {styled} from "../stitches.config"

export const MessageArea = styled(
    'div',
    {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        contentEditable: 'true',
        variants:{
            type: {
                user: {
                    margin: '0px 25px 0px 0px',
                    justifyContent: 'flex-end'
                },
                assistant: {
                    margin: '0px 0px 0px 25px',
                    justifyContent: 'flex-start'
                }
            }
        }
    }
)


export const MessageContainer = styled(
    'div',
    {
        width: '65%',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        borderRadius: '20px',
        padding: '15px',
        variants:{
            type: {
                user: {
                    backgroundColor: '$darkgrayHeading',
                },
                assistant: {
                    backgroundColor: '$lightgray',
                }
            }
        }


    }
)

export const HeaderContainer = styled(
    'div',
    {
        display: 'grid',
        gridTemplateColumns: "2fr 5fr 2fr",
        gridTemplateRows: "auto",
        margin: '2rem',

    }
)

export const ContentContainer = styled(
    'div',
    {
        display: 'grid',
        gridTemplateColumns: "2fr 5fr 2fr",
        gridTemplateRows: 'auto',
    }
)
export const DemographicsContainer = styled(
    'div',
    {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '1rem',
        gap: '1rem',
        border: '2px solid $darkgray',
        borderRadius: '10px',
        padding: '10px',
        height: '80vh'
    }
)


export const InformationContainer = styled(
    'div',
    {
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',

    }
)


export const PageContainer = styled(
    'div',
    {
        display: 'grid',
        gridTemplateColumns: "1fr 10fr",
        width: '100vw',
        height:' 100vh',


    }
)

export const ConversationContainer = styled(
    'div',
        {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '1rem',
        marginRight: '1rem',
        gap: '1rem',
        border: '2px solid $darkgray',
        borderRadius: '10px',
        padding: '10px',
        height: '80vh',
        overflowY: 'scroll',
        overflowX: 'hidden'
    }
)


export const HistoryContainer = styled(
    'div',
    {
        display: 'flex',
        flexDirection: 'column',
        marginRight: '1rem',
        gap: '1rem',
        border: '2px solid $darkgray',
        borderRadius: '10px',
        padding: '10px',
        height: '80vh',
        overflowY: 'scroll'
    }
)

export const ActivityDiv = styled(
    'div',
    {
        display: 'flex',
        flexDirection: 'column',

    }
)

export const NavContainer = styled(
    'div',
    {
        display: 'flex',
        flexDirection: 'column',
        gap:'20px',
        backgroundColor: '$lightgray',
        paddingTop: '2rem',
        alignItems: 'center'

    }
)
